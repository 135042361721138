import React from 'react'
import PropTypes from 'prop-types'
import { Home } from 'react-feather'
import { Layout, SEO, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Image, Card } from 'react-bootstrap'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

const ShowCareer = ({ pageContext }) => {
  const {
    banner,
    title,
    description,
    slug,
    topNav,
    accreditedHmos,
  } = pageContext
  
  const { favicon } = topNav
  return (
    <>
      <SEO title={"Careers - " + title} favicon={favicon}/>
      <Layout activeNav='careers' data={topNav}>
        <Container className="my-4 md-mt-10 sm-mt-7">
          <Breadcrumb className="breadcrumb-wrapper mx-0">
            <Breadcrumb.Item href="/">
              <Home className="text-violet mr-2" size={14} />
              Home
          </Breadcrumb.Item>
            <Breadcrumb.Item href="/our-facilities">
              Careers
          </Breadcrumb.Item>
            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
          </Breadcrumb>

          <Row>
            <Col lg={4} md={12} sm={12} className="my-3 justify-content-center">
              <Image src={banner || 'https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/banner.png'} alt={slug} className="custom-image-xxxl" />
            </Col>
            <Col lg={8} md={12} sm={12} className="my-3">
              <Card>
                <Card.Body>
                  <h2 className="text-dark-slate-blue mt-2 mb-1">{title}</h2>
                  <div className="my-4">
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                </Card.Body>
                <div className="border-dashed-bottom my-2" />

                <Card.Body>
                  <h5 className="mb-2">Share this article to</h5>
                  <Row>
                    <Col>
                      <FacebookShareButton
                        url={process.env.MH_URL+"/career/"+slug}
                        className="btn btn-outline-light btn-lg btn-block d-flex flex-row align-items-center border text-dark"
                      >
                        <Image src={require("../../images/facebook-logo.png")} className="image-h-icon" />
                        <span className="w-100 text-center">
                          Facebook
                      </span>
                      </FacebookShareButton>
                    </Col>
                    <Col>
                      <TwitterShareButton
                        url={process.env.MH_URL+"/career/"+slug}
                        className="btn btn-outline-light btn-lg btn-block d-flex flex-row align-items-center border text-dark"
                      >
                        <Image src={require("../../images/twitter-logo.png")} className="image-h-icon" />
                        <span className="w-100 text-center">
                          Twitter
                      </span>
                      </TwitterShareButton>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <HMOSlider data={accreditedHmos} />
      </Layout>
    </>
  )
}

ShowCareer.propTypes = {
  pageContext: PropTypes.shape({
    banner: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.any,
    slug: PropTypes.string.isRequired,
    topNav: PropTypes.object.isRequired,
    accreditedHmos: PropTypes.array.isRequired,
  }).isRequired
}

export default ShowCareer
